
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class DiffForm extends Vue {
  @Prop() memberId!: any;
  newForm: { [index: string]: any } = {};
  oldForm: { [index: string]: any } = {};
  editMode = true;

  async mounted() {
    const token = localStorage.getItem("token");

    await this.$api
      .get(`/members/${this.memberId}/get-diff`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        this.newForm = response.data.newProps;
        this.oldForm = response.data.oldProps;
      });
  }

  diffs($model: string) {
    return this.oldForm[$model] !== this.newForm[$model];
  }
}
