
import Vue from "vue";
import SubmitForm from "@/components/SubmitForm.vue";
import Component from "vue-class-component";
import FormDialog from "@/views/FormDialog.vue";
import Axios from "axios";

@Component({
  components: { FormDialog, SubmitForm },
})
export default class Form extends Vue {
  tokenIsValid = false;
  open = false;
  memberId = null;
  prefill = null;
  success = false;

  mounted() {
    this.verifyToken();
  }

  get token() {
    return this.$route.query.token;
  }

  async verifyToken() {
    if (!this.token) {
      return;
    }

    await this.$api
      .post("/verify-member-token", {
        token: this.token,
      })
      .then((response: any) => {
        this.tokenIsValid = true;
        this.memberId = response.data.data.id;
        this.prefill = response.data.data.properties;
      })
      .catch((error: any) => {
        this.open = true;
        this.success = false;
      });
  }

  async submitForm(val: any) {
    await this.$api
      .put("/member-update", {
        id: this.memberId,
        properties: val,
        token: this.token,
      })
      .then((response: any) => {
        this.success = true;
        this.open = true;
      })
      .catch(() => {
        this.open = true;
      });
  }
}
