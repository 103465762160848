
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";

@Component
export default class FormDialog extends Vue {
  @VModel() open!: boolean;
  @Prop({ required: true }) success!: boolean;
}
