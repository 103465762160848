
import Vue from "vue";
import LoginForm from "@/components/LoginForm.vue";
import Component from "vue-class-component";

@Component({
  components: {
    LoginForm,
  },
})
export default class LogIn extends Vue {}
